<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <Filtros
      v-if="!isLoadingFilters"
      :user="user"
      :marcas="marcas"
      :modelos="modelos"
      :type-auto="typeAuto"
      :combustibles="combustibles"
      :transmisiones="transmisiones"
      @refresh="refresh"
    />
    <v-card-text v-if="!isLoading">
      <v-row>
        <v-col
          v-if="(permisos.includes('car:create') || permisos.includes('*')) && !offline"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>
        <v-col
          v-if="permisos.includes('car:create') && !offline"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            outlined
            @click="uploadLote"
          >
            <v-icon>{{ icons.mdiCloudUpload }}</v-icon>
            <span class="pl-2">{{ $t('btn.uploadLote') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          v-if="user.nivel === -1 && permisos.includes('exportation:list')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            outlined
            @click="exportar"
          >
            <v-icon>mdi-export</v-icon>
            <span class="pl-2">{{ $t('menu.export') }}</span>
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="isLoading">
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="text"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card-text>

    <!--<v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="model.marca_id"
            :items="itemsMarcas"
            :search-input.sync="searchMarca"
            hide-details
            hide-selected
            :label="$t('menu.marca')"
            outlined
            dense
            item-text="name"
            item-value="id"
            @change="changeMarca"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.marca') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-avatar v-if="item.icon !== null">
                <v-img
                  size="20"
                  :src="rutaPublic + item.icon"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-avatar v-else>
                <v-avatar
                  color="primary"
                  size="30"
                >
                  <span class="white--text text-h5">{{ item.name.substr(0, 1) }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="model.modelo_id"
            :items="modelos"
            item-text="name"
            item-value="id"
            :label="$t('menu.modelo')"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="model.transmision_id"
            :items="transmisiones"
            item-text="name"
            item-value="id"
            :label="$t('menu.trasnmision')"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="model.tipo_auto_id"
            :items="itemsType"
            :search-input.sync="searchType"
            hide-details
            hide-selected
            :label="$t('menu.typeCar')"
            outlined
            dense
            item-text="name"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.typeCar') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="model.name"
            :label="$t('lbl.car')"
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>-->
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <template v-if="!isLoadingFilters">
        <v-row>
          <v-col
            cols="12"
            md="1"
          >
            <v-btn
              v-if="
                model.marca_id ||
                  model.modelo_id ||
                  model.transmision_id ||
                  model.tipo_auto_id ||
                  model.name ||
                  model.not_public ||
                  model.sin_marca ||
                  model.sin_modelo ||
                  model.sin_transmision ||
                  model.sin_tipo_auto ||
                  model.sin_combustible ||
                  model.sin_cant_puertas ||
                  model.sin_cant_paxs ||
                  model.sin_cant_bag_big ||
                  model.sin_cant_bag_small ||
                  model.sin_um ||
                  model.sin_capacidad_comb ||
                  model.sin_consumo_comb ||
                  model.sin_motor ||
                  model.sin_caracteristicas ||
                  model.sin_prioridad ||
                  model.sin_fotos ||
                  model.sin_tag_fotos ||
                  model.sin_tag_principal
              "
              class="mx-2"
              fab
              outlined
              small
              color="error"
              @click="clearSearch()"
            >
              <v-icon small>
                {{ icons.mdiEraserVariant }}
              </v-icon>
            </v-btn>
          </v-col>
          <!--<v-col
            cols="12"
            md="2"
            class="text-left text-lowercase"
          >
            <v-btn
              v-if="contNotPublic > 0"
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.not_public === false ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="model.not_public === null ? 'error' : ''"
              @click="
                model.not_public === null ? model.not_public = false : model.not_public = null
                load()
              "
            >
              {{ contNotPublic }} {{ $t('lbl.notPublic') }}
            </v-btn>
          </v-col>-->
          <v-col
            v-if="totalInfoPendiente > 0"
            cols="12"
            md="2"
            class="text-left text-lowercase"
          >
            <v-btn
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="showInfoPendient ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!showInfoPendient ? 'error' : ''"
              @click="infoPendient"
            >
              {{ totalInfoPendiente }} {{ $t('lbl.infoPendiente') }}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>

          <v-col
            v-if="!isLoadingInfo"
            cols="12"
            md="2"
          >
            <!--{{ productsPaginate.length }} {{ $t('lbl.de') }} {{ productsAll.length }} {{ $t('lbl.cars') }}-->
            {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('lbl.cars') }}
          </v-col>

          <v-col
            v-if="isLoadingInfo"
            cols="12"
            md="4"
          >
            <v-progress-circular
              :size="20"
              indeterminate
              color="primary"
              class="mr-2"
            ></v-progress-circular>
            {{ $t('lbl.loadingCarProduct') }}
          </v-col>
        </v-row>

        <v-row v-if="showInfoPendient">
          <v-col
            cols="12"
            md="12"
            class="text-left text-lowercase"
          >
            <v-btn
              v-if="totalNotPublic > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.not_public ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.not_public ? 'error' : ''"
              @click="changeNotPublic()"
            >
              {{ totalNotPublic }} {{ $t('lbl.notPublic') }}
            </v-btn>

            <v-btn
              v-if="totalSinMarca > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_marca ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_marca ? 'error' : ''"
              @click="changeSinMarca()"
            >
              {{ totalSinMarca }} {{ $t('lbl.sinMarca') }}
            </v-btn>

            <v-btn
              v-if="totalSinModelo > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_modelo ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_modelo ? 'error' : ''"
              @click="changeSinModelo()"
            >
              {{ totalSinModelo }} {{ $t('lbl.sinModelo') }}
            </v-btn>

            <v-btn
              v-if="totalSinTransmision > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_transmision ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_transmision ? 'error' : ''"
              @click="changeSinTransmision()"
            >
              {{ totalSinTransmision }} {{ $t('lbl.sinTransmision') }}
            </v-btn>

            <v-btn
              v-if="totalSinCarroseria > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_tipo_auto ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_tipo_auto ? 'error' : ''"
              @click="changeSinCarroseria()"
            >
              {{ totalSinCarroseria }} {{ $t('lbl.sinCarroceria') }}
            </v-btn>

            <v-btn
              v-if="totalSinCombustible > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_combustible ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_combustible ? 'error' : ''"
              @click="changeSinCombustible()"
            >
              {{ totalSinCombustible }} {{ $t('lbl.sinCombustible') }}
            </v-btn>

            <v-btn
              v-if="totalSinCantPuertas > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_cant_puertas ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_cant_puertas ? 'error' : ''"
              @click="changeSinCantPuertas()"
            >
              {{ totalSinCantPuertas }} {{ $t('lbl.sinCantPuertas') }}
            </v-btn>

            <v-btn
              v-if="totalSinCantPaxs > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_cant_paxs ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_cant_paxs ? 'error' : ''"
              @click="changeSinCantPaxs()"
            >
              {{ totalSinCantPaxs }} {{ $t('lbl.sinCantPaxs') }}
            </v-btn>

            <v-btn
              v-if="totalSinBagBig > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_cant_bag_big ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_cant_bag_big ? 'error' : ''"
              @click="changeSinCantBagBig()"
            >
              {{ totalSinBagBig }} {{ $t('lbl.sinCantBagBig') }}
            </v-btn>

            <v-btn
              v-if="totalSinBagSmall > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_cant_bag_small ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_cant_bag_small ? 'error' : ''"
              @click="changeSinCantBagSmall()"
            >
              {{ totalSinBagSmall }} {{ $t('lbl.sinCantBagSmall') }}
            </v-btn>

            <v-btn
              v-if="totalSinUm > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_um ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_um ? 'error' : ''"
              @click="changeSinUm()"
            >
              {{ totalSinUm }} {{ $t('lbl.sinUm') }}
            </v-btn>

            <v-btn
              v-if="totalSinCapacidadComb > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_capacidad_comb ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_capacidad_comb ? 'error' : ''"
              @click="changeSinCapacidad()"
            >
              {{ totalSinCapacidadComb }} {{ $t('lbl.sinCapacidad') }}
            </v-btn>

            <v-btn
              v-if="totalSinConsumoComb > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_consumo_comb ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_consumo_comb ? 'error' : ''"
              @click="changeSinConsumo()"
            >
              {{ totalSinConsumoComb }} {{ $t('lbl.sinConsumoComb') }}
            </v-btn>

            <v-btn
              v-if="totalSinMotor > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_motor ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_motor ? 'error' : ''"
              @click="changeSinMotor()"
            >
              {{ totalSinMotor }} {{ $t('lbl.sinMotor') }}
            </v-btn>

            <v-btn
              v-if="totalSinCaracteristicas > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_caracteristicas ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_caracteristicas ? 'error' : ''"
              @click="changeSinCaracteristicas()"
            >
              {{ totalSinCaracteristicas }} {{ $t('lbl.sinCaracteristicas') }}
            </v-btn>

            <v-btn
              v-if="totalSinPrioridad > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_prioridad ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_prioridad ? 'error' : ''"
              @click="changeSinPrioridad()"
            >
              {{ totalSinPrioridad }} {{ $t('lbl.sinPrioridad') }}
            </v-btn>

            <v-btn
              v-if="totalSinFotos > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_fotos ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_fotos ? 'error' : ''"
              @click="changeSinFotos()"
            >
              {{ totalSinFotos }} {{ $t('lbl.sinFotos') }}
            </v-btn>

            <v-btn
              v-if="totalSinTagFotos > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_tag_fotos ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_tag_fotos ? 'error' : ''"
              @click="changeSinTagFotos()"
            >
              {{ totalSinTagFotos }} {{ $t('lbl.sinTagFotos') }}
            </v-btn>

            <v-btn
              v-if="totalSinTagPrincipal > 0"
              class="mx-2 mt-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_tag_principal ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_tag_principal ? 'error' : ''"
              @click="changeSinTagPrincipal()"
            >
              {{ totalSinTagPrincipal }} {{ $t('lbl.sinTagPrincipal') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <!--<template v-if="isLoadingFilters">
        <v-row>
          <v-col
            cols="12"
            md="1"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="button"
            ></v-skeleton-loader>
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="text-left text-lowercase"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="button"
            ></v-skeleton-loader>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            md="2"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>-->
    </v-form>

    <template v-if="!isLoading">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                <!--@click="orderBy('name')"-->
                <span
                  v-if="orderByName === null"
                  style="cursor: pointer;"
                >
                  {{ $t('lbl.car') }}
                </span>
                <v-btn
                  v-else
                  style="background-color: #FAFAFA;"
                  @click="orderBy('name')"
                >
                  {{ $t('lbl.car') }}
                  <v-icon
                    small
                    right
                    :color="orderByName === null ? '' : 'primary'"
                  >
                    {{ orderByName !== null ? (orderByName ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                  </v-icon>
                </v-btn>
              </th>
              <th
                width="15%"
                class="text-uppercase"
              >
                <!--@click="orderBy('marca')"-->
                <span
                  v-if="orderByMarca === null"
                  style="cursor: pointer;"
                >
                  {{ $t('menu.marca') }}
                </span>
                <v-btn
                  v-else
                  style="background-color: #FAFAFA;"
                  @click="orderBy('marca')"
                >
                  {{ $t('menu.marca') }}
                  <v-icon
                    small
                    right
                    :color="orderByMarca === null ? '' : 'primary'"
                  >
                    {{ orderByMarca !== null ? (orderByMarca ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                  </v-icon>
                </v-btn>
              </th>
              <th class="text-uppercase">
                <!--@click="orderBy('modelo')"-->
                <span
                  v-if="orderByModelo === null"
                  style="cursor: pointer;"
                >
                  {{ $t('menu.modelo') }}
                </span>
                <v-btn
                  v-else
                  style="background-color: #FAFAFA;"
                  @click="orderBy('modelo')"
                >
                  {{ $t('menu.modelo') }}
                  <v-icon
                    small
                    right
                    :color="orderByModelo === null ? '' : 'primary'"
                  >
                    {{ orderByModelo !== null ? (orderByModelo ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                  </v-icon>
                </v-btn>
              </th>
              <th class="text-uppercase">
                <!--@click="orderBy('trasnmision')"-->
                <span
                  v-if="orderByTransmision === null"
                  style="cursor: pointer;"
                >
                  {{ $t('menu.trasnmision') }}
                </span>
                <v-btn
                  v-else
                  style="background-color: #FAFAFA;"
                  @click="orderBy('trasnmision')"
                >
                  {{ $t('menu.trasnmision') }}
                  <v-icon
                    small
                    right
                    :color="orderByTransmision === null ? '' : 'primary'"
                  >
                    {{
                      orderByTransmision !== null ? (orderByTransmision ? icons.mdiArrowUp : icons.mdiArrowDown) : null
                    }}
                  </v-icon>
                </v-btn>
              </th>
              <th class="text-uppercase">
                <!--@click="orderBy('typeCar')"-->
                <span
                  v-if="orderByTypeAuto === null"
                  style="cursor: pointer;"
                >
                  {{ $t('menu.typeCar') }}
                </span>
                <v-btn
                  v-else
                  style="background-color: #FAFAFA;"
                  @click="orderBy('typeCar')"
                >
                  {{ $t('menu.typeCar') }}
                  <v-icon
                    small
                    right
                    :color="orderByTypeAuto === null ? '' : 'primary'"
                  >
                    {{ orderByTypeAuto !== null ? (orderByTypeAuto ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                  </v-icon>
                </v-btn>
              </th>
              <th class="text-uppercase">
                <!--@click="orderBy('combustible')"-->
                <span
                  v-if="orderByCombusible === null"
                  style="cursor: pointer;"
                >
                  {{ $t('menu.combustible') }}
                </span>
                <v-btn
                  v-else
                  style="background-color: #FAFAFA;"
                  @click="orderBy('combustible')"
                >
                  {{ $t('menu.combustible') }}
                  <v-icon
                    small
                    right
                    :color="orderByCombusible === null ? '' : 'primary'"
                  >
                    {{ orderByCombusible !== null ? (orderByCombusible ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                  </v-icon>
                </v-btn>
              </th>
              <!--<th class="text-uppercase">
                {{ $t('lbl.details') }}
              </th>-->
              <th
                v-if="permisos.includes('car:edit') || permisos.includes('*')"
                width="150px"
                class="text-uppercase"
              >
                {{ $t('lbl.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!--productsPaginate-->
            <tr
              v-for="(iten, index) in items"
              :key="index"
            >
              <td>
                {{ iten.name }}
                <v-chip
                  v-if="!iten.publico"
                  class="ma-2"
                  color="red"
                  text-color="white"
                >
                  {{ $t('lbl.sinPublicar') }}
                </v-chip>
              </td>
              <td>
                <v-row>
                  <v-col
                    cols="12"
                    md="9"
                    sm="9"
                  >
                    {{ iten.marca.name }}
                    <!--<p>
                      <v-avatar
                        v-if="iten.marca.icon === null"
                        color="primary"
                        size="25"
                      >
                        <span
                          class="white--text text-h5"
                        >{{ iten.marca.name.substr(0, 1) }}</span>
                      </v-avatar>
                      <img
                        v-else
                        :src="rutaPublic + iten.marca.icon"
                        :alt="iten.marca.name"
                        height="25"
                      >
                    </p>-->
                  </v-col>
                </v-row>
              </td>
              <td>{{ iten.modelo_id !== null ? iten.modelo.name : '' }}</td>
              <td>{{ iten.transmision_id !== null ? iten.transmision.name : '' }}</td>
              <td>{{ iten.tipo_auto_id !== null ? iten.tipo_auto.name : '' }}</td>
              <td>{{ iten.combustible_id !== null ? iten.combustible.name : '' }}</td>
              <!--<td>
                <v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiEye }}</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    <v-card class="mx-auto">
                      <v-card-text>
                        <div class="text--primary">
                          <span>{{ $t('lbl.cantPuertas') }}: {{ iten.cant_puertas }}</span><br />
                          <span>{{ $t('lbl.cantPaxs') }}: {{ iten.cant_paxs }}</span><br />
                          <span>{{ $t('lbl.cantMaletas') }} ({{ $t('lbl.small') }}): {{ iten.cant_maletas_small }}</span><br />
                          <span>{{ $t('lbl.cantMaletas') }} ({{ $t('lbl.big') }}): {{ iten.cant_maletas_big }}</span><br />
                          <span>{{ $t('lbl.capacityComb') }}: {{ iten.capacidad_combustible }}
                            {{ iten.um_combustible }}</span><br />
                          <span>{{ $t('lbl.consumoComb') }}: {{ iten.consumo_combustible }}
                            {{ iten.um_combustible !== undefined ? `km/${iten.um_combustible}` : '' }}</span><br />
                          <span>{{ $t('lbl.motor') }}: {{ iten.motor }}</span><br />

                          <br />
                          <strong> {{ $t('menu.caracteristicas') }} </strong><br />
                          <span
                            v-for="(cara, i) in caracteristicas"
                            :key="i"
                          >
                            <span
                              v-for="(caraIten, j) in iten.caracteristicas_id"
                              :key="`j${j}`"
                            >
                              <span v-if="caraIten === cara.id">{{ cara.name }}<br /></span>
                            </span>
                          </span>
                        </div>
                      </v-card-text>
                    </v-card>
                  </span>
                </v-tooltip>
              </td>-->
              <td v-if="permisos.includes('car:edit') || permisos.includes('car:destroy') || permisos.includes('*')">
                <v-menu
                  v-if="
                    permisos.includes('car:edit') || permisos.includes('car:list') || permisos.includes('*')
                  "
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="permisos.includes('car:edit')"
                      link
                      @click="editItem(iten)"
                    >
                      <v-list-item-title>
                        <v-btn
                          icon
                        >
                          <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                        </v-btn>
                        <span>{{ $t('btn.edit') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="permisos.includes('car:list') || permisos.includes('*')"
                      link
                      @click="seeItem(iten)"
                    >
                      <v-list-item-title>
                        <v-btn
                          icon
                        >
                          <v-icon>{{ icons.mdiEye }}</v-icon>
                        </v-btn>
                        <span>{{ $t('btn.see') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="permisos.includes('car:destroy')"
                      link
                      @click="deleteItem(iten)"
                    >
                      <v-list-item-title>
                        <v-btn
                          icon
                        >
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                        <span>{{ $t('btn.delete') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="12"
            cols="12"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              total-visible="5"
              @input="onPageChange"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <v-skeleton-loader
      v-else
      class="mx-auto"
      type="table-tbody"
    ></v-skeleton-loader>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteCar', { n: item.name }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEye,
  mdiCloudUpload,
} from '@mdi/js'

import Filtros from '../utils/Filtros.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Filtros,
  },
  data() {
    return {
      isLoading: false,
      isLoadingFilters: false,
      isLoadingInfo: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      orderByName: null,
      orderByMarca: null,
      orderByModelo: null,
      orderByTransmision: null,
      orderByTypeAuto: null,
      orderByCombusible: null,
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEye,
        mdiCloudUpload,
      },
      isDialogVisible: false,
      model: {
        not_public: false,
      },
      semanas: [],
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      itemsType: [],
      searchType: null,
      loading: false,
      caracteristicas: [],

      showRow: false,
      indexRow: -1,

      item: {},
      itemId: null,
      isDialogVisibleDelete: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),

      modelos: [],
      transmisiones: [],
      marcas: [],
      combustibles: [],
      itemsMarcas: [],
      searchMarca: null,
      itemsModelos: [],
      searchModelo: null,

      typeAuto: [],
      itemsTypeAuto: [],
      searchTypeAuto: null,

      showInfoPendient: false,
      totalNotPublic: 0,
      totalInfoPendiente: 0,
      totalSinMarca: 0,
      totalSinModelo: 0,
      totalSinTransmision: 0,
      totalSinCarroseria: 0,
      totalSinCombustible: 0,
      totalSinCantPuertas: 0,
      totalSinCantPaxs: 0,
      totalSinBagSmall: 0,
      totalSinBagBig: 0,
      totalSinUm: 0,
      totalSinCapacidadComb: 0,
      totalSinConsumoComb: 0,
      totalSinMotor: 0,
      totalSinCaracteristicas: 0,
      totalSinPrioridad: 0,
      totalSinFotos: 0,
      totalSinTagFotos: 0,
      totalSinTagPrincipal: 0,
      user: {},
      offline: false,
      itemsPerPage: 10,
    }
  },
  computed: {
    ...mapState({
      posCotizador: state => state.app.posCotizador,
      productsAll: state => state.app.productsAll,
      productsPaginate: state => state.app.productsPaginate,
      carsProductCache: state => state.app.carsProductCache,
      carsProductFiltersCache: state => state.app.carsProductFiltersCache,

      // itemsPerPage: state => state.app.itemsPerPage,
      itemsPage: state => state.app.itemsPage,

      // filtersCars: state => state.app.filtersCars,
      // contNotPublic: state => state.app.contNotPublic,
      // contInfoPendient: state => state.app.contInfoPendient,
    }),
  },
  watch: {
    searchMarca(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterMarca(val.toLowerCase())
      } else {
        this.itemsMarcas = []
      }
    },
    searchModelo(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterModelo(val.toLowerCase())
      } else {
        this.itemsModelos = []
      }
    },
    searchType(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterTypeAuto(val.toLowerCase())
      } else {
        this.itemsType = []
      }
    },
  },
  created() {
    this.items = this.carsProductCache
    this.totalNotPublic = this.carsProductFiltersCache.notPublic
    this.totalInfoPendiente = this.carsProductFiltersCache.totalInfoPendiente
    this.totalSinMarca = this.carsProductFiltersCache.totalSinMarca
    this.totalSinModelo = this.carsProductFiltersCache.totalSinModelo
    this.totalSinTransmision = this.carsProductFiltersCache.totalSinTransmision
    this.totalSinCarroseria = this.carsProductFiltersCache.totalSinCarroseria
    this.totalSinCombustible = this.carsProductFiltersCache.totalSinCombustible
    this.totalSinCantPuertas = this.carsProductFiltersCache.totalSinCantPuertas
    this.totalSinCantPaxs = this.carsProductFiltersCache.totalSinCantPaxs
    this.totalSinBagSmall = this.carsProductFiltersCache.totalSinBagSmall
    this.totalSinBagBig = this.carsProductFiltersCache.totalSinBagBig
    this.totalSinUm = this.carsProductFiltersCache.totalSinUm
    this.totalSinCapacidadComb = this.carsProductFiltersCache.totalSinCapacidadComb
    this.totalSinConsumoComb = this.carsProductFiltersCache.totalSinConsumoComb
    this.totalSinMotor = this.carsProductFiltersCache.totalSinMotor
    this.totalSinCaracteristicas = this.carsProductFiltersCache.totalSinCaracteristicas
    this.totalSinPrioridad = this.carsProductFiltersCache.totalSinPrioridad
    this.totalSinFotos = this.carsProductFiltersCache.totalSinFotos
    this.totalSinTagFotos = this.carsProductFiltersCache.totalSinTagFotos
    this.totalSinTagPrincipal = this.carsProductFiltersCache.totalSinTagPrincipal

    this.profile()
    this.fetchDataFromEndpoints()

    // this.getMarcas()
    // this.getTransmisiones()
    // this.getTypeAuto()
    // this.getCombustibles()
    // this.getCaracteristicas()
  },
  methods: {
    ...mapMutations([
      'setPosCotizador',
      'setItemsCont',
      'setItemsPerPage',
      'setItemsPage',
      'updateProductsAll',
      'setProductsPaginate',
      'updateProductsPaginate',
      'filtrarCars',
      'initContCars',
    ]),
    scroll() {
      window.onscroll = () => {
        const { scrollTop } = document.documentElement
        const scrollHeight = document.documentElement.offsetHeight
        const heightWin = window.innerHeight
        const scrollPosition = Math.ceil(heightWin + scrollTop)

        if (scrollPosition >= scrollHeight) {
          // PAGINAR SI LOS FILTROS ESTAN LIMPIO
          const filters1 = JSON.stringify({
            marca_id: null,
            modelo_id: null,
            transmision_id: null,
            tipo_auto_id: null,
            name: null,
            not_public: null,
            sin_marca: null,
            sin_modelo: null,
            sin_transmision: null,
            sin_tipo_auto: null,
            sin_combustible: null,
            sin_cant_puertas: null,
            sin_cant_paxs: null,
            sin_cant_bag_big: null,
            sin_cant_bag_small: null,
            sin_um: null,
            sin_capacidad_comb: null,
            sin_consumo_comb: null,
            sin_motor: null,
            sin_caracteristicas: null,
            sin_prioridad: null,
            sin_fotos: null,
            sin_tag_fotos: null,
            sin_tag_principal: null,
          })
          const filters2 = JSON.stringify(this.filtersCars)

          if (filters1 === filters2) {
            const reserv = []
            // eslint-disable-next-line no-plusplus
            for (let index = (this.itemsPage * this.itemsPerPage); index < this.productsAll.length; index++) {
              const element = this.productsAll[index]

              if (index < ((this.itemsPage + 1) * this.itemsPerPage)) {
                reserv.push(element)
              }
            }
            this.setItemsPage(this.itemsPage + 1)
            this.updateProductsPaginate(reserv)
          }
        }
      }
    },
    refresh(json) {
      this.model = json
      this.pagination = {
        current: 1,
        total: 0,
      }
      this.load()
    },
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.company_id) {
        if (this.user.company) {
          if (this.user.company.offline) {
            this.offline = this.user.company.offline
          }
        }
      }

      /* this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
          if (this.user.company_id) {
            if (this.user.company) {
              if (this.user.company.offline) {
                this.offline = this.user.company.offline
              }
            }
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.load()
          this.loadAll()
        }) */
    },
    async fetchDataFromEndpoints() {
      const jsonItems = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.model,
        // eslint-disable-next-line no-nested-ternary
        orderByName: this.orderByName !== null ? (this.orderByName ? 11 : 22) : 11,
        // eslint-disable-next-line no-nested-ternary
        orderByMarca: this.orderByMarca !== null ? (this.orderByMarca ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByModelo: this.orderByModelo !== null ? (this.orderByModelo ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByTransmision: this.orderByTransmision !== null ? (this.orderByTransmision ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByTypeAuto: this.orderByTypeAuto !== null ? (this.orderByTypeAuto ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByCombusible: this.orderByCombusible !== null ? (this.orderByCombusible ? 11 : 22) : null,
        perfil_slug: localStorage.getItem('perfil'),
      }
      const jsonFilters = {
        search: this.model,
      }
      const jsonAll = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        perfil_slug: localStorage.getItem('perfil'),
      }

      try {
        const [
          resItems,
          resFilters,
          resAll,
          resMarcas,
          resTransmicion,
          resTypoAuto,
          resCombustible,
          resCaract,
        ] = await Promise.all([
          this.axios
            .post('cars/list', jsonItems, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('cars/all-filter', jsonFilters, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('cars/list', jsonAll, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_marca_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_transmision_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_type_auto_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_combustible_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_caracteristica_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        // eslint-disable-next-line no-empty
        if (resItems.data.success === false) { } else {
          this.items = resItems.data.data
          this.pagination.current = resItems.data.meta.current_page
          this.pagination.total = resItems.data.meta.last_page
          this.totalF = resItems.data.meta.total
        }

        // eslint-disable-next-line no-empty
        if (resFilters.data.success === false) { } else {
          this.totalNotPublic = resFilters.data.notPublic
          this.totalInfoPendiente = resFilters.data.totalInfoPendiente

          this.totalSinMarca = resFilters.data.totalSinMarca
          this.totalSinModelo = resFilters.data.totalSinModelo
          this.totalSinTransmision = resFilters.data.totalSinTransmision
          this.totalSinCarroseria = resFilters.data.totalSinCarroseria
          this.totalSinCombustible = resFilters.data.totalSinCombustible
          this.totalSinCantPuertas = resFilters.data.totalSinCantPuertas
          this.totalSinCantPaxs = resFilters.data.totalSinCantPaxs
          this.totalSinBagSmall = resFilters.data.totalSinBagSmall
          this.totalSinBagBig = resFilters.data.totalSinBagBig
          this.totalSinUm = resFilters.data.totalSinUm
          this.totalSinCapacidadComb = resFilters.data.totalSinCapacidadComb
          this.totalSinConsumoComb = resFilters.data.totalSinConsumoComb
          this.totalSinMotor = resFilters.data.totalSinMotor
          this.totalSinCaracteristicas = resFilters.data.totalSinCaracteristicas
          this.totalSinPrioridad = resFilters.data.totalSinPrioridad
          this.totalSinFotos = resFilters.data.totalSinFotos
          this.totalSinTagFotos = resFilters.data.totalSinTagFotos
          this.totalSinTagPrincipal = resFilters.data.totalSinTagPrincipal
        }

        // eslint-disable-next-line no-empty
        if (resAll.data.success === false) { } else {
          this.totalItems = resAll.data.meta.total
        }

        this.marcas = resMarcas.data.data
        this.transmisiones = resTransmicion.data.data
        this.typeAuto = resTypoAuto.data.data
        this.combustibles = resCombustible.data.data
        this.caracteristicas = resCaract.data.data
      } catch (err) {
        console.error('Error fetching data:', err)
      } finally {
        this.isLoading = false
        this.loading = false
        this.isLoadingFilters = false

        this.isLoadingInfo = false
      }
    },
    load() {
      this.loading = true

      // this.axios.get(`flights?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${this.model}&perfil_slug=${localStorage.getItem('perfil')}`, {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.model,
        // eslint-disable-next-line no-nested-ternary
        orderByName: this.orderByName !== null ? (this.orderByName ? 11 : 22) : 11,
        // eslint-disable-next-line no-nested-ternary
        orderByMarca: this.orderByMarca !== null ? (this.orderByMarca ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByModelo: this.orderByModelo !== null ? (this.orderByModelo ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByTransmision: this.orderByTransmision !== null ? (this.orderByTransmision ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByTypeAuto: this.orderByTypeAuto !== null ? (this.orderByTypeAuto ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByCombusible: this.orderByCombusible !== null ? (this.orderByCombusible ? 11 : 22) : null,
        perfil_slug: localStorage.getItem('perfil'),
      }

      this.axios
        .post('cars/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            /* this.$toast.error(this.$t('msg.noAutorice'))
          this.$store.commit('logout') */
          } else {
            /* this.setItemsCont(res.data.total)
            this.updateProductsAll(res.data.data)
            const reserv = []
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < res.data.data.length; index++) {
              const element = res.data.data[index]

              if (index < this.itemsPerPage) {
                reserv.push(element)
              }
            }
            this.setProductsPaginate(reserv) */

            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page
            this.totalF = res.data.meta.total
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoading = false
          this.allFilter()

          // this.initContCars()
        })
    },
    allFilter() {
      const json = {
        search: this.model,
      }

      this.axios
        .post('cars/all-filter', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            /* this.$toast.error(this.$t('msg.noAutorice'))
          this.$store.commit('logout') */
          } else {
            this.totalNotPublic = res.data.notPublic
            this.totalInfoPendiente = res.data.totalInfoPendiente

            this.totalSinMarca = res.data.totalSinMarca
            this.totalSinModelo = res.data.totalSinModelo
            this.totalSinTransmision = res.data.totalSinTransmision
            this.totalSinCarroseria = res.data.totalSinCarroseria
            this.totalSinCombustible = res.data.totalSinCombustible
            this.totalSinCantPuertas = res.data.totalSinCantPuertas
            this.totalSinCantPaxs = res.data.totalSinCantPaxs
            this.totalSinBagSmall = res.data.totalSinBagSmall
            this.totalSinBagBig = res.data.totalSinBagBig
            this.totalSinUm = res.data.totalSinUm
            this.totalSinCapacidadComb = res.data.totalSinCapacidadComb
            this.totalSinConsumoComb = res.data.totalSinConsumoComb
            this.totalSinMotor = res.data.totalSinMotor
            this.totalSinCaracteristicas = res.data.totalSinCaracteristicas
            this.totalSinPrioridad = res.data.totalSinPrioridad
            this.totalSinFotos = res.data.totalSinFotos
            this.totalSinTagFotos = res.data.totalSinTagFotos
            this.totalSinTagPrincipal = res.data.totalSinTagPrincipal
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loading = false
          this.isLoadingFilters = false
          this.loadAll()
        })
    },
    loadAll() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        perfil_slug: localStorage.getItem('perfil'),
      }
      this.axios
        .post('cars/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },

    filterMarca(v) {
      this.itemsMarcas = []
      if (v === '') {
        this.itemsMarcas = []
      } else {
        this.itemsMarcas = this.marcas.filter(e => e.name.toLowerCase())
      }
    },
    filterModelo(v) {
      this.itemsModelos = []
      if (v === '') {
        this.itemsModelos = []
      } else {
        this.itemsModelos = this.modelos.filter(e => e.name.toLowerCase())
      }
    },
    filterTypeAuto(v) {
      this.itemsTypeAuto = []
      if (v === '') {
        this.itemsType = []
      } else {
        this.itemsType = this.typeAuto.filter(e => e.name.toLowerCase())
      }
    },
    orderBy(attr) {
      if (attr === 'name') {
        if (this.orderByName === null) {
          this.orderByName = true
        } else {
          this.orderByName = !this.orderByName
        }

        // APAGAR
        this.orderByMarca = null
        this.orderByModelo = null
        this.orderByTransmision = null
        this.orderByTypeAuto = null
        this.orderByCombusible = null
      } else if (attr === 'marca') {
        if (this.orderByMarca === null) {
          this.orderByMarca = true
        } else {
          this.orderByMarca = !this.orderByMarca
        }

        // APAGAR
        this.orderByName = null
        this.orderByModelo = null
        this.orderByTransmision = null
        this.orderByTypeAuto = null
        this.orderByCombusible = null
      } else if (attr === 'modelo') {
        if (this.orderByModelo === null) {
          this.orderByModelo = true
        } else {
          this.orderByModelo = !this.orderByModelo
        }

        // APAGAR
        this.orderByName = null
        this.orderByMarca = null
        this.orderByTransmision = null
        this.orderByTypeAuto = null
        this.orderByCombusible = null
      } else if (attr === 'trasnmision') {
        if (this.orderByTransmision === null) {
          this.orderByTransmision = true
        } else {
          this.orderByTransmision = !this.orderByTransmision
        }

        // APAGAR
        this.orderByName = null
        this.orderByMarca = null
        this.orderByModelo = null
        this.orderByTypeAuto = null
        this.orderByCombusible = null
      } else if (attr === 'typeCar') {
        if (this.orderByTypeAuto === null) {
          this.orderByTypeAuto = true
        } else {
          this.orderByTypeAuto = !this.orderByTypeAuto
        }

        // APAGAR
        this.orderByName = null
        this.orderByMarca = null
        this.orderByModelo = null
        this.orderByTransmision = null
        this.orderByCombusible = null
      } else if (attr === 'combustible') {
        if (this.orderByCombusible === null) {
          this.orderByCombusible = true
        } else {
          this.orderByCombusible = !this.orderByCombusible
        }

        // APAGAR
        this.orderByName = null
        this.orderByMarca = null
        this.orderByModelo = null
        this.orderByTransmision = null
        this.orderByTypeAuto = null
      }

      this.load()
    },

    infoPendient() {
      this.showInfoPendient = !this.showInfoPendient
    },
    changeNotPublic() {
      /* if (this.model.not_public) {
        this.model.not_public = false
      } else {
        this.model.not_public = true
      } */
      this.model.not_public = !this.model.not_public
      this.load()
    },
    changeSinMarca() {
      if (this.model.sin_marca) {
        this.model.sin_marca = null
      } else {
        this.model.sin_marca = 11
      }
      this.load()
    },
    changeSinModelo() {
      if (this.model.sin_modelo) {
        this.model.sin_modelo = null
      } else {
        this.model.sin_modelo = 11
      }
      this.load()
    },
    changeSinTransmision() {
      if (this.model.sin_transmision) {
        this.model.sin_transmision = null
      } else {
        this.model.sin_transmision = 11
      }
      this.load()
    },
    changeSinCarroseria() {
      if (this.model.sin_tipo_auto) {
        this.model.sin_tipo_auto = null
      } else {
        this.model.sin_tipo_auto = 11
      }
      this.load()
    },
    changeSinCombustible() {
      if (this.model.sin_combustible) {
        this.model.sin_combustible = null
      } else {
        this.model.sin_combustible = 11
      }
      this.load()
    },
    changeSinCantPuertas() {
      if (this.model.sin_cant_puertas) {
        this.model.sin_cant_puertas = null
      } else {
        this.model.sin_cant_puertas = 11
      }
      this.load()
    },
    changeSinCantPaxs() {
      if (this.model.sin_cant_paxs) {
        this.model.sin_cant_paxs = null
      } else {
        this.model.sin_cant_paxs = 11
      }
      this.load()
    },
    changeSinCantBagBig() {
      if (this.model.sin_cant_bag_big) {
        this.model.sin_cant_bag_big = null
      } else {
        this.model.sin_cant_bag_big = 11
      }
      this.load()
    },
    changeSinCantBagSmall() {
      if (this.model.sin_cant_bag_small) {
        this.model.sin_cant_bag_small = null
      } else {
        this.model.sin_cant_bag_small = 11
      }
      this.load()
    },
    changeSinUm() {
      if (this.model.sin_um) {
        this.model.sin_um = null
      } else {
        this.model.sin_um = 11
      }
      this.load()
    },
    changeSinCapacidad() {
      if (this.model.sin_capacidad_comb) {
        this.model.sin_capacidad_comb = null
      } else {
        this.model.sin_capacidad_comb = 11
      }
      this.load()
    },
    changeSinConsumo() {
      if (this.model.sin_consumo_comb) {
        this.model.sin_consumo_comb = null
      } else {
        this.model.sin_consumo_comb = 11
      }
      this.load()
    },
    changeSinMotor() {
      if (this.model.sin_motor) {
        this.model.sin_motor = null
      } else {
        this.model.sin_motor = 11
      }
      this.load()
    },
    changeSinCaracteristicas() {
      if (this.model.sin_caracteristicas) {
        this.model.sin_caracteristicas = null
      } else {
        this.model.sin_caracteristicas = 11
      }
      this.load()
    },
    changeSinPrioridad() {
      if (this.model.sin_prioridad) {
        this.model.sin_prioridad = null
      } else {
        this.model.sin_prioridad = 11
      }
      this.load()
    },
    changeSinFotos() {
      if (this.model.sin_fotos) {
        this.model.sin_fotos = null
      } else {
        this.model.sin_fotos = 11
      }
      this.load()
    },
    changeSinTagFotos() {
      if (this.model.sin_tag_fotos) {
        this.model.sin_tag_fotos = null
      } else {
        this.model.sin_tag_fotos = 11
      }
      this.load()
    },
    changeSinTagPrincipal() {
      if (this.model.sin_tag_principal) {
        this.model.sin_tag_principal = null
      } else {
        this.model.sin_tag_principal = 11
      }
      this.load()
    },

    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      localStorage.removeItem('cars-show')
      localStorage.removeItem('cars-id')
      localStorage.removeItem('cars-clone')
      this.$router.push({ name: 'cars-update' })
    },
    seeItem(iten) {
      localStorage.setItem('cars-show', true)
      localStorage.setItem('cars-id', iten.id)
      this.$router.push({ name: 'cars-update' })
    },
    uploadLote() {
      localStorage.removeItem('cars-id')
      localStorage.removeItem('cars-clone')
      this.$router.push({ name: 'cars-upload-lote' })
    },
    editItem(iten) {
      localStorage.removeItem('cars-show')
      localStorage.setItem('cars-id', iten.id)
      this.$router.push({ name: 'cars-update' })
    },
    cloneItem(iten) {
      localStorage.setItem('cars-id', iten.id)
      localStorage.setItem('cars-clone', true)
      this.$router.push({ name: 'cars-update' })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`cars/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else if (res.data.data.status === 402) {
              this.$toast.error(this.$t('msg.errorDeleteCar', { n: this.item.name }))
            }
          } else {
            // this.pagination.current = 1
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisibleDelete = false
      this.itemId = null
      this.loading = false
      this.load()
      this.loadAll()
    },

    clearSearch() {
      this.model = {
        not_public: false,
      }
      this.showInfoPendient = false
      this.itemsMarcas = []
      this.itemsModelos = []
      this.itemsTypeAuto = []
      this.load()
    },

    changeMarca() {
      if (this.model.marca_id) {
        const marc = this.marcas.filter(a => a.id === this.model.marca_id)[0]
        this.modelos = marc.modelos
      } else {
        this.modelos = []
      }
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },

    exportar() {
      localStorage.setItem('entity-export', 'cars')
      this.$router.push({ name: 'cars-export' })
    },

    /*
    getMarcas() {
      this.axios
        .get(`nom_marca_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.marcas = res.data.data
        })
    },
    getTransmisiones() {
      this.axios
        .get(`nom_transmision_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.transmisiones = res.data.data
        })
    },
    getTypeAuto() {
      this.axios
        .get(`nom_type_auto_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.typeAuto = res.data.data
        })
    },
    getCombustibles() {
      this.axios
        .get(`nom_combustible_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.combustibles = res.data.data
        })
    },
    getCaracteristicas() {
      this.axios
        .get(`nom_caracteristica_cars?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.caracteristicas = res.data.data
        })
    },
    */
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
